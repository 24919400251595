<template>
    <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="percentage < 1 && !hideLoading"
        style="
            z-index: 2147483647;
            height: 100vh;
            width: 100%;
            position: absolute;
            top: 0;
            background: black;
        "
    >
        <div
            class="d-flex flex-column justify-content-center align-items-center"
            style="gap: 2.5rem; margin-top: -2rem"
        >
            <img src="@/assets/logo-text.png" style="width: 10rem" />
            <div
                style="
                    width: 200px;
                    border-radius: 1000px;
                    display: inline-block;
                    border: 1px solid #4d4d4d;
                    height: 5px;
                "
            >
                <div
                    :style="{ width: Math.ceil(percentage * 100) + '%' }"
                    style="
                        background-color: white;
                        height: 5px;
                        border-radius: 1000px;
                    "
                ></div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-center" style="z-index: 1000">
        <video
            ref="video"
            playsinline
            autoplay
            muted
            loop
            style="
                pointer-events: none;
                user-select: none;
                height: 100vh;
                width: 100vw;
                background: white;
                object-fit: cover;
            "
        ></video>
        <div
            style="
                width: 100%;
                height: 100vh;
                background: black;
                opacity: 0.4;
                position: absolute;
                top: 0;
            "
        ></div>
        <div
            class="d-flex flex-column"
            style="z-index: 1000; position: absolute; top: 0"
        >
            <div
                style="height: 25vh"
                class="text-white px-4 d-flex flex-row justify-content-center align-items-end"
            >
                <div class="mt-4">
                    <img src="@/assets/logo-text.png" style="width: 100px" />
                    <h1 class="mt-4">
                        {{ t('pages.login.heading') }}
                    </h1>
                </div>
            </div>
            <div style="height: 50vh" class="d-flex align-items-center">
                <div
                    class="card rounded-sm border-0 p-4 d-inline-block shadow-sm mx-4"
                    style="width: 100%"
                    v-if="!resetPassword"
                >
                    <div class="mb-3">
                        <label for="email" class="form-label">{{
                            t('pages.login.email')
                        }}</label>
                        <input
                            v-model="email"
                            name="email"
                            type="email"
                            class="form-control"
                            :class="{ 'is-invalid': errors }"
                            id="email"
                            placeholder="john.doe@example.com"
                            required
                        />
                        <div class="invalid-feedback d-block" v-if="errors">
                            {{ errors.message }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">{{
                            t('pages.login.password')
                        }}</label>
                        <input
                            v-on:keydown.enter="login"
                            v-model="password"
                            name="password"
                            type="password"
                            class="form-control"
                            id="password"
                            placeholder="···········"
                            required
                        />
                    </div>
                    <button @click="login" class="w-100 btn btn-primary mb-3">
                        {{ t('pages.login.login') }}
                    </button>
                    <div class="dropdown">
                        <button
                            @click.prevent="switchLanguage()"
                            class="w-100 btn btn-light mb-3"
                        >
                            <span v-if="i18n.locale.value == 'fr'">
                                English
                            </span>
                            <span v-else> Francais </span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="dropdown-item" href="#">English</a>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#">Français</a>
                            </li>
                        </ul>
                    </div>
                    <a
                        style="font-weight: 500"
                        @click="resetPassword = true"
                        href="#"
                        >{{ t('pages.login.forgotPassword') }}</a
                    >
                </div>
                <div
                    v-if="resetPassword"
                    class="card rounded-sm border-0 p-4 d-inline-block shadow-sm mx-4"
                    style="width: 100%"
                >
                    <div v-if="!resetPasswordSuccess">
                        <div class="d-flex flex-row align-items-center mb-3">
                            <button
                                @click="resetPassword = false"
                                class="back-btn rounded"
                            >
                                <i class="fa fa-chevron-left"></i>
                            </button>
                            <h2 class="m-0">
                                {{ t('pages.resetPassword.resetPassword') }}
                            </h2>
                        </div>
                        <div v-if="!loadingResetPassword">
                            <p>
                                {{ t('pages.resetPassword.desc') }}
                            </p>
                            <div class="mb-4">
                                <label for="email" class="form-label"
                                    >E-mail</label
                                >
                                <input
                                    type="text"
                                    id="email"
                                    v-model="resetPasswordEmail"
                                    @keyup.enter="submitResetPassword"
                                    class="form-control"
                                    :class="[
                                        resetPasswordEmailError
                                            ? 'is-invalid'
                                            : '',
                                    ]"
                                    placeholder="email@example.com"
                                />
                                <div
                                    class="invalid-feedback d-block"
                                    v-if="resetPasswordEmailError"
                                >
                                    {{ resetPasswordEmailErrorText }}
                                </div>
                            </div>
                            <button
                                @click="submitResetPassword"
                                class="btn btn-primary w-100"
                            >
                                {{ t('pages.resetPassword.resetPassword') }}
                            </button>
                        </div>
                        <div
                            class="d-flex flex-row justify-content-center align-items-center"
                            style="min-height: 150px"
                            v-else
                        >
                            <img
                                src="@/assets/loading-icon.gif"
                                style="width: 1.5rem; height: 1.5rem"
                            />
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex flex-row align-items-center mb-3">
                            <button
                                @click="
                                    resetPassword = false;
                                    resetPasswordSuccess = false;
                                "
                                class="back-btn rounded"
                            >
                                <i class="fa fa-chevron-left"></i>
                            </button>
                            <h2 class="m-0">
                                {{ t('pages.resetPassword.resetLinkSent') }}
                            </h2>
                        </div>
                        <p>
                            {{ t('pages.resetPassword.instructionsSent') }}
                        </p>
                        <button
                            class="btn bg-light w-100"
                            @click="
                                resetPassword = false;
                                resetPasswordSuccess = false;
                            "
                        >
                            {{ t('misc.modal.done') }}
                        </button>
                    </div>
                </div>
            </div>
            <div style="height: 25vh"></div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useUserStore } from '@/stores/user-store';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const i18n = useI18n();
const { t } = useI18n();

onMounted(() => {
    if (navigator.language.includes('fr')) {
        i18n.locale.value = 'fr';
    } else {
        i18n.locale.value = 'en';
    }
});

const router = useRouter();
const resetPassword = ref(false);
const userStore = useUserStore();
//const videoFile = require('@/assets/Fiart-39-Seawalker.mp4');
const videoFile = 'https://videos.files.wordpress.com/Q0TEa2QZ/shortened.mp4';
const video = ref(null);
const percentage = ref(0);
const hideLoading = ref(false);
const secondsSinceStartLoad = ref(0);

onMounted(() => {
    // Preload video, showing progressbar
    var r = new XMLHttpRequest();

    r.onprogress = function (event) {
        if (event.lengthComputable) {
            // evt.loaded the bytes the browser received
            // evt.total the total bytes set by the header
            percentage.value = event.loaded / event.total;
        } else {
            percentage.value = 1;
        }
    };

    r.open('GET', videoFile);

    const interval = setInterval(() => {
        secondsSinceStartLoad.value++;
        // If video doesn't load within 6 seconds, show the login anyways
        if (secondsSinceStartLoad.value > 6 && percentage.value < 1) {
            hideLoading.value = true;
            clearInterval(interval);
        }
    }, 1000);

    r.onload = function () {
        video.value.setAttribute('src', URL.createObjectURL(r.response));
        video.value.play();
        video.value.currentTime = 10;
    };

    r.responseType = 'blob';
    r.send();

    // Check if route has parameter "reset" to show the reset password dialog
    const route = useRoute();
    if (route.params.reset != null && route.params.reset == 'reset') {
        resetPassword.value = true;
    }
});

function switchLanguage() {
    if (i18n.locale.value == 'fr') {
        i18n.locale.value = 'en';
    } else {
        i18n.locale.value = 'fr';
    }
}

let email = ref('');
let password = ref('');
let errors = ref(null);

const login = async () => {
    try {
        let res = await axios.post('login', {
            email: email.value,
            password: password.value,
            locale: i18n.locale.value,
        });
        userStore.setUserDetails(res.data);
        router.push('/');
    } catch (err) {
        errors.value = err.response.data;
        password.value = '';
    }
};

const resetPasswordEmail = ref(null);
const resetPasswordEmailError = ref(false);
const resetPasswordEmailErrorText = ref(null);
const resetPasswordSuccess = ref(false);
const loadingResetPassword = ref(false);

async function submitResetPassword() {
    try {
        if (!resetPasswordEmail.value || resetPasswordEmail.value == '') {
            resetPasswordSuccess.value = false;
            loadingResetPassword.value = false;
            resetPasswordEmailError.value = true;
            resetPasswordEmailErrorText.value = t(
                'pages.resetPassword.emailEmpty'
            );
        } else {
            loadingResetPassword.value = true;
            await axios.post('/reset-password/login-page', {
                email: resetPasswordEmail.value,
            });
            loadingResetPassword.value = false;
            resetPasswordSuccess.value = true;
        }
    } catch (err) {
        resetPasswordSuccess.value = false;
        loadingResetPassword.value = false;
        if (err.response.data.error == 'email_does_not_exist') {
            resetPasswordEmailError.value = true;
            resetPasswordEmailErrorText.value = t(
                'pages.resetPassword.couldntFind'
            );
        }
    }
}
</script>

<style scoped>
.back-btn {
    padding: 10px;
    padding-left: 0;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    color: var(--bs-primary);
    width: 40px;
    height: 40px;
    justify-content: center;
}

.back-btn i {
    font-size: 1.4rem;
}
</style>
